/*
let s:foreground = "c5c8c6"
let s:background = "1d1f21"
let s:selection = "373b41"
let s:line = "282a2e"
let s:comment = "969896"
let s:red = "cc6666"
let s:orange = "de935f"
let s:yellow = "f0c674"
let s:green = "b5bd68"
let s:aqua = "8abeb7"
let s:blue = "81a2be"
let s:purple = "b294bb"
let s:window = "4d5057"
*/

body {
  background-color: #1d1f21;
  margin: 0;
  font-family: 'Source Code Pro', monospace;
  text-align: center;
}

h1,h2,h3,h4,h5,h6 {
  color: #b294bb;
  font-weight: 600;
  margin: 0;
}
h1 { font-size: 2em; }
h2 { font-size: 1.75em; }
h3 { font-size: 1.5em; }
h4 { font-size: 1.25em; }
h5 { font-size: 1em; }
h6 { font-size: 0.75em; }

p,a {
  font-size: 0.85em;
  line-height: 1.4;
}
p { color: #c5c8c6; }
a { color: #8abeb7; }

button,textarea {
  font-family: inherit;
}
