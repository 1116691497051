$textbox-color: #2d2f31;

.iampj {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 600px;

  h1 {
    margin: 50px 0 15px 0;
  }

  p {
    text-align: left;
  }

  textarea {
    background-color: $textbox-color;
    border: 1px solid $textbox-color;
    color: #8abeb7;
    display: block;
    margin: 30px 0 15px 0;
    outline: none;
    resize: none;

    &:active, &:focus {
      border: 1px solid #4d4f51;
      transition: border 0.2s;
    }
  }

  button {
    background-color: #b71c1c;
    border: none;
    border-radius: 2px;
    color: white;
    font-size: 1em;
    font-weight: bold;
    padding: 6px 15px;
    outline: none;

    &:active {
      background-color: #af0f0f;
    }
  }
}