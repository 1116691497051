.home {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .desc {
    margin-top: 20px;

    a, p {
      display: inline;
    }
  }

  h1 { padding-top: 50px; }
  h4 {
    color: #81a2be;
    font-weight: 400;
    padding-top: 15px;
  }

  .quote-wrapper {
    box-sizing: border-box;
    margin: 5px auto 0 auto;
    max-width: 600px;
    padding: 10px 20px;
    text-align: left;

    > .quote {
      margin: 12px 0;
      padding: 0px 20px;
      border-left: 3px solid rgb(51, 51, 51);
      p {
        &::before, &::after { content: "\""}
        font-weight: 300;
        padding: 6px 0;
        color: #f0c674;
      }
    }
  }

  .tent {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    min-height: 150px;

    img {
      display: block;
      height: 150px;
    }
  }

  ul {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;

    li {
      list-style: none;
      padding: 0 10px;
      border-right: 1px solid #666;

      a { display: block; }
      &:last-child { border: none; }
    }
  }
}
