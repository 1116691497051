.blog {
  h2 { margin-top: 50px; }
  .desc { font-size: 0.95em; }

  .entries {
    margin: 60px auto 0 auto;
    max-width: 760px;
    text-align: left;
    h4 { color: #cc6666; }
    p { color: #969896; }
  }
}